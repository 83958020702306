<template>
  <a-layout class="ticket-view">
    <TitleBreadcrumb />
    <a-spin :spinning="loading" :tip="labels.loadingText">
      <div v-if="ticket" class="content-base container">
        <a-row type="flex" justify="space-between" :gutter="[16, 16]">
          <a-col>
            <h2 class="ticket-view-title">
              {{ labels.playDetails }}{{ ticket.number }}
            </h2>
          </a-col>
          <a-col>
            <template v-if="ticket.is_cancellable">
              <a-button
                type="danger"
                class="btn-cancel"
                icon="close-circle"
                @click="handleConfirmCancellation(ticket)"
              >
                {{ labels.confirmCancellation.cancelLabel }}
              </a-button>
              <a-divider type="vertical" />
            </template>
            <a-button class="btn-print" icon="printer" @click="print">{{
              labels.print
            }}</a-button>
            <!-- SHARE BUTTON -->
            <a-divider type="vertical" />
            <a-button
              :disabled="sharing"
              class="btn-share"
              @click="handleShareTicket(ticket)"
            >
              <font-awesome-icon class="anticon" :icon="['fas', 'share']" />
              {{ labels.shareBtn }}
            </a-button>
            <a-divider type="vertical" />
            <a-button class="btn-rebuy" @click="handleReBuyTicket(ticket)">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'arrows-rotate']"
              />
              {{ labels.reBuyBtn }}
            </a-button>
          </a-col>
        </a-row>
        <a-card class="ticket-view-card" id="ticket-print-area">
          <a-row type="flex" justify="center" :gutter="[16, 16]">
            <a-col :xs="24">
              <h3 class="ticket-view-card-title">
                {{ labels.appName.toUpperCase() }}
              </h3>
              <h4 class="ticket-view-card-subtitle">
                {{ labels.guaranteePayment }}
              </h4>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center" :gutter="[16, 16]">
            <a-col :xs="24" :md="12">
              <span class="ticket-view-card-label">{{
                labels.ticketNoLabel
              }}</span>
              <span class="ticket-view-card-value">{{ ticket.number }}</span>
            </a-col>
            <a-col
              :xs="24"
              :md="12"
              :style="{ textAlign: isMobile ? 'left' : 'right' }"
            >
              <span class="ticket-view-card-label">{{ labels.dateLabel }}</span>
              <span class="ticket-view-card-value">{{
                ticket.date | formatDate
              }}</span>
            </a-col>
          </a-row>
          <a-row
            type="flex"
            justify="center"
            :gutter="[16, 16]"
            class="no-print"
          >
            <a-col :xs="24" :md="12">
              <span class="ticket-view-card-label"
                >{{
                  ticket.user.isSeller
                    ? labels.sellerLabel.toUpperCase()
                    : labels.clientLabel.toUpperCase()
                }}:
              </span>
              <span class="ticket-view-card-value"
                >{{ ticket.user.firstname }} {{ ticket.user.lastname }}</span
              >
            </a-col>
            <a-col
              :xs="24"
              :md="12"
              :style="{ textAlign: isMobile ? 'left' : 'right' }"
            >
              <span class="ticket-view-card-label">{{
                labels.phoneLabel
              }}</span>
              <span class="ticket-view-card-value">{{
                ticket.user.whatsapp | VMask("(###) ###-####")
              }}</span>
            </a-col>
          </a-row>
          <a-row
            v-if="ticket.status === 'cancelled'"
            type="flex"
            justify="end"
            :gutter="[16, 16]"
          >
            <a-col
              :xs="24"
              :md="12"
              :style="{ textAlign: isMobile ? 'left' : 'right' }"
            >
              <span class="ticket-view-card-label">{{
                labels.statusLabel
              }}</span>
              <span class="ticket-view-card-value">
                <a-tag color="red">
                  {{ labels.statuses[ticket.status] | ucfirst }}
                </a-tag>
              </span>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center" :gutter="[16, 16]">
            <a-col :span="24">
              <a-divider />
              <a-table
                v-if="customDetails.length"
                :dataSource="customDetails"
                :pagination="false"
                :columns="columns"
                :rowKey="'id'"
                :size="'small'"
                :bordered="false"
                :class="'ticket-component-table'"
                :defaultExpandAllRows="true"
                :expandIcon="expandIcon"
                :expandRowByClick="false"
                :rowClassName="
                  (row) =>
                    `table-row ${
                      row.isLottery ? 'table-row-disabled-hover' : ''
                    } ${row.won_amount > 0 ? 'table-row-winning' : ''}`
                "
              />
              <a-divider />
            </a-col>
          </a-row>
          <a-row type="flex" justify="space-between" :gutter="[16, 16]">
            <a-col>
              <span class="ticket-view-card-total-label">{{
                labels.totalLabel.toUpperCase()
              }}</span>
            </a-col>
            <a-col>
              <span class="ticket-view-card-total-value"
                >${{ ticket.total_amount | formatNumber(2, ",", ".") }}</span
              >
            </a-col>
          </a-row>
          <a-row
            v-if="ticket.won_total_amount > 0"
            type="flex"
            justify="space-between"
            :gutter="[16, 16]"
          >
            <a-col>
              <span class="ticket-view-card-total-label">{{
                labels.wonTotalLabel.toUpperCase()
              }}</span>
            </a-col>
            <a-col>
              <span class="ticket-view-card-total-value"
                >${{
                  ticket.won_total_amount | formatNumber(2, ",", ".")
                }}</span
              >
            </a-col>
          </a-row>
          <a-row type="flex" justify="center" :gutter="[16, 16]">
            <a-col :xs="24" :style="{ paddingTop: '60px' }">
              <h3 class="ticket-view-card-title small">
                {{ labels.checkTicketLabel }}
              </h3>
              <h3 class="ticket-view-card-title small">
                {{ labels.errorAreNotPaidLabel }}
              </h3>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </a-spin>
  </a-layout>
</template>
<script>
import loadingMixin from "@/mixins/loadingMixin";
import TitleBreadcrumb from "@/components/layout/client/TitleBreadcrumb.vue";
import { pageTitle } from "@/utils/utils";
import labels from "@/utils/labels";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import accounting from "accounting";
import _ from "lodash";
import { DATE_FORMAT, generateUniqueID } from "../../../utils/utils";
export default {
  components: { TitleBreadcrumb },
  name: "TicketView",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.ticketView.subtitle),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.ticketView.subtitle,
      },
    ],
  },
  beforeRouteEnter(to, _, next) {
    if (!to.params.code) {
      next({ name: "ticket-list" });
      return;
    }
    next();
  },
  data() {
    return {
      labels: { ...labels.ticketView, appName: labels.appName },
      ticket: null,
      loading: false,
      sharing: false,
    };
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 592;
    },
    customDetails() {
      const { details = [] } = this.ticket;
      //NOTE: GROUP BY LOTTERY AND SECOND LOTTERY
      const lotteries = Object.values(
        _.cloneDeep(details).reduce((acc, play) => {
          const lottery = play.lottery;
          const secondLottery = play.secondLottery;
          const key = secondLottery?.id
            ? `${lottery.id}-${secondLottery.id}`
            : lottery.id;
          if (!acc[key]) {
            acc[key] = {
              id: this.uuid(),
              lottery,
              secondLottery,
              isLottery: true,
              children: [],
            };
          }
          acc[key].children.push(play);
          return acc;
        }, {})
      );
      return lotteries;
    },
    columns() {
      return [
        {
          title: this.isMobile
            ? this.labels.table.typeShort.toUpperCase()
            : this.labels.table.type.toUpperCase(),
          dataIndex: "type",
          key: "type",
          width: "70px",
          customRender: (text, record) => {
            if (record?.isLottery) {
              const lotteryName = record.secondLottery?.id
                ? `${record.lottery.name} - ${record.secondLottery.name}`
                : record.lottery.name;
              return {
                children: <span class="text-hover">{lotteryName}</span>,
                attrs: {
                  colSpan: this.columns.length,
                },
              };
            }
            return this.$options.filters.ucfirst(text);
          },
        },
        {
          title: this.labels.table.numbers.toUpperCase(),
          dataIndex: "numbers",
          key: "numbers",
          width: "60px",
          align: "center",
          customRender: (numbers, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return numbers;
          },
        },
        {
          title: this.labels.table.amount.toUpperCase(),
          dataIndex: "amount",
          key: "amount",
          width: "60px",
          align: "right",
          customRender: (amount, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return accounting.formatNumber(amount, 2, ",", ".");
          },
        },
        {
          title: this.labels.table.wonAmount.toUpperCase(),
          dataIndex: "won_amount",
          key: "won_amount",
          width: "60px",
          align: "right",
          hidden: this.ticket?.won_total_amount === 0,
          customRender: (amount, record) => {
            if (record.isLottery) {
              return {
                attrs: {
                  colSpan: 0,
                },
              };
            }
            return accounting.formatNumber(amount, 2, ",", ".");
          },
        },
      ].filter(({ hidden = false }) => !hidden);
    },
  },
  methods: {
    ...mapActions("orders", ["fetchTicket", "cancelTicket"]),
    uuid() {
      return generateUniqueID();
    },
    shareTicketLink(uuid) {
      return `${window.location.origin}/buy?shared=${uuid}`;
    },
    expandIcon({ expandable }) {
      return expandable ? "" : " ";
    },
    async handleFetchTicket(number) {
      try {
        this.loading = true;
        const { data } = await this.fetchTicket(number);
        this.ticket = data;
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        if (error.isAxiosError && error.response.status === 404) {
          this.$router.push({ name: "ticket-list" });
        }
      } finally {
        this.loading = false;
      }
    },
    async print() {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: ["/print.css"],
        timeout: 1000,
        autoClose: false,
        windowTitle: window.document.title,
      };
      await this.$htmlToPaper("ticket-print-area", options);
    },
    handleConfirmCancellation(record) {
      this.$confirm({
        title: this.labels.confirmCancellation.title,
        content: this.labels.confirmCancellation.content.replace(
          "{code}",
          record.number
        ),
        okText: this.labels.confirmCancellation.okText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirmCancellation.cancelText,
        onOk: async () => {
          this.handleCancellation(record);
        },
      });
    },
    async handleCancellation(record) {
      this.loading = true;
      try {
        const response = await this.cancelTicket(record.number);
        this.ticket = response.data;
        this.$message.success(
          this.labels.confirmCancellation.successCancellation
        );
        this.checkSession();
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.messageerror.message ||
            this.labels.confirmCancellation.errorCancellation
        );
      } finally {
        this.loading = false;
      }
    },
    prepareTicketData(record) {
      return {
        number: record.number,
        userId: record.user.id,
        details: record.details
          .map((detail) => ({
            lottery: {
              id: detail.lottery.id,
              name: detail.lottery.name,
              abbreviated: detail.lottery.abbreviated,
            },
            numbers: detail.numbers,
            amount: detail.amount,
            type: detail.type,
            ...(detail.secondLottery && {
              secondLottery: {
                id: detail.secondLottery.id,
                name: detail.secondLottery.name,
                abbreviated: detail.secondLottery.abbreviated,
              },
            }),
          }))
          .reduce((acc, curr) => {
            if (
              !acc.find(
                (d) =>
                  d.numbers === curr.numbers &&
                  d.type === curr.type &&
                  d.amount === curr.amount
              )
            ) {
              acc.push(curr);
            }
            return acc;
          }, []),
      };
    },
    async handleShareTicket(record) {
      try {
        this.sharing = true;
        const data = this.prepareTicketData(record);
        const secret = process.env.VUE_APP_CRYTO_KEY;
        const UUID = this.$CryptoJS.AES.encrypt(
          window.btoa(JSON.stringify(data)),
          secret
        )?.toString();
        const link = await this.shareTicketLink(UUID);
        const shareData = {
          title: this.labels.shareTitle,
          url: link,
        };
        if (navigator.share) {
          await navigator.share(shareData);
        } else {
          this.$copyText(link);
          this.$message.success(this.labels.copiedToClipboard);
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message ||
            this.labels.shareError
        );
      } finally {
        this.sharing = false;
      }
    },
    async handleReBuyTicket(record) {
      try {
        const data = this.prepareTicketData(record);
        const secret = process.env.VUE_APP_CRYTO_KEY;
        const UUID = this.$CryptoJS.AES.encrypt(
          window.btoa(JSON.stringify(data)),
          secret
        )?.toString();
        this.$router.push({ name: "buy", query: { rebuy: UUID } });
      } catch (error) {
        this.$message.error(this.labels.rebuyError);
      }
    },
  },
  filters: {
    formatDate(value) {
      return moment(value).format(DATE_FORMAT.MOMENT_DATE_TIME_LONG);
    },
  },
  watch: {
    "$route.params.code": {
      handler: function (value, oldValue) {
        if (!_.isEqual(value, oldValue)) {
          this.handleFetchTicket(value);
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.ticket = null;
    this.loading = false;
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";

.ticket-view {
  max-width: 100% !important;
  font-family: "Roboto", sans-serif;
  background-color: transparent;

  @media screen and (max-width: $screen-mobile) {
    max-width: 100% !important;
  }

  &-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 16px;
    color: color(--white);
  }

  .content-base {
    padding-top: 60px;

    @media screen and (max-width: $screen-mobile) {
      max-width: 100% !important;
    }

    .btn-print {
      color: color(c-hover);
      border-color: color(c-hover);
      background-color: color(c-secondary);
      transition: all 0.5s ease;

      &:hover {
        color: color(c-primary);
        border-color: color(c-hover);
        background-color: color(c-hover);
        transition: all 0.5s ease;
      }
    }

    .btn-rebuy,
    .btn-share {
      color: color(c-secondary);
      border-color: color(c-secondary);
      background-color: color(c-hover);
      transition: all 0.5s ease;

      &:hover {
        color: color(c-hover);
        border-color: color(c-hover);
        background-color: color(c-primary);
        transition: all 0.5s ease;
      }
    }
  }

  &-card {
    background-color: color(c-secondary);
    border-color: color(c-secondary);
    padding: 1.125rem;
    border-radius: 10px;

    @media screen and (max-width: $screen-mobile) {
      padding: 2.125rem 1rem;
      width: 100%;
    }

    &-title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      line-height: 1.2;

      &.small {
        font-size: 1rem;
      }
      &.smaller {
        font-size: 0.8rem;
      }
    }

    &-subtitle {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 20px;
      color: color(--white);
      text-align: center;
    }

    &-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
    }

    &-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      border-bottom: 1px dotted color(--white);
    }

    &-total-label {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
    }

    &-total-value {
      font-size: 1.125rem;
      font-weight: 500;
      margin-bottom: 2px;
      color: color(--white);
      text-align: center;
      border-bottom: 1px dashed color(--white);
    }
  }

  .ant-table {
    &:hover {
      transition: 0.5s ease;
      background: unset;
    }

    tr.ant-table-row:hover {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.25) !important;
    }

    tr:nth-child(2n) {
      transition: 0.5s ease;
      background: rgba(0, 0, 0, 0.2) !important;
    }

    tr.table-row-disabled,
    tr.table-row-disabled:hover {
      color: color(c-primary);
      background: color(danger-light) !important;
      font-weight: 600;
    }

    tr.table-row-disabled-hover:hover,
    tr.table-row-disabled-hover {
      background: none !important;
      font-weight: 700;
      font-size: 16px;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      transition: 0.5s ease;
      background: none !important;
    }

    tr.table-row-winning,
    tr.table-row-winning:hover {
      td:nth-child(1) {
        border-left: 10px solid color(success) !important;
      }
    }

    &-tbody,
    &-thead {
      color: color(--white) !important;

      tr {
        th {
          color: color(--white) !important;
        }
      }
    }

    &-footer {
      background: transparent;
    }

    @media screen and (max-width: $screen-mobile) {
      &-small {
        border: none;
      }

      &-body {
        margin: 0 1px;
      }
    }
  }
}
</style>
